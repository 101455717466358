<template>
  <v-footer id="default-footer" absolute app inset>
    <links />
  </v-footer>
</template>

<script>
// Components
import Links from '@/components/theme/layout/Links'

export default {
  name: 'DefaultFooter',
  components: { Links }
}
</script>

<style scoped>
#default-footer {
  border-top: 2px solid #ddd;
}
</style>

<template>
  <v-container>
    <v-row align="center">
      <v-col
        v-for="(link, i) in links"
        :key="i"
        class="text-center"
        cols="6"
        md="auto"
      >
        <v-icon small class="mr-1">{{ link.icon }}</v-icon>
        <a
          :href="link.href"
          class="text-decoration-none text-uppercase text-caption font-weight-regular"
          rel="noopener"
          target="_blank"
          v-text="link.text"
        />
      </v-col>

      <v-spacer class="hidden-sm-and-down" />

      <v-col cols="12" md="auto">
        <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
          &copy; {{ new Date().getFullYear() }} MMO S.p.A.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Links',
  data() {
    return {
      links: [
        {
          href: 'mailto:si@makemoneyorganization.com',
          text: this.$t('common.help'),
          icon: 'mdi-lifebuoy'
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
a
  color: inherit !important
</style>
